const { slugify } = require('../../../src/common/utils/slugify')

const Areas = [
  { area: '' },
  {
    area: 'BARCELONA',
    provincia: 'BARCELONA',
    slug: 'barcelona',
    poblacion: { exclude: 'BARCELONA' },
    lat: '41.3887901',
    lon: '2.1589899',
  },
  // Any changes in Barcelona area, consider
  // also hardcoded 'BARCELONA' in talleres.tsx and Directorio.tsx
  {
    area: 'BARCELONA CIUDAD',
    slug: 'barcelona-ciudad',
    provincia: 'BARCELONA',
    poblacion: { include: 'BARCELONA' },
    lat: '41.3887901',
    lon: '2.1589899',
    skip: false, // We now want to generate this area as a page
  },
  {
    area: 'GIRONA',
    provincia: 'GIRONA',
    slug: 'girona',
    lat: '41.9831085',
    lon: '2.82493',
  },
  {
    area: 'HUESCA',
    provincia: 'HUESCA',
    slug: 'huesca',
    lat: '42.1361500',
    lon: '-0.4087000',
  },
  {
    area: 'LLEIDA',
    provincia: 'LLEIDA',
    slug: 'lleida',
    lat: '41.6167412',
    lon: '0.62218',
  },
  {
    area: 'TARRAGONA',
    provincia: 'TARRAGONA',
    slug: 'tarragona',
    lat: '41.1290200',
    lon: '1.2490100',
  },
  {
    area: 'TERUEL',
    provincia: 'TERUEL',
    slug: 'teruel',
    lat: '40.3456000',
    lon: '-1.1064600',
  },
  {
    area: 'ZARAGOZA',
    provincia: 'ZARAGOZA',
    slug: 'zaragoza',
    lat: '41.6560600',
    lon: '-0.8773400',
  },
  {
    area: 'ANDORRA',
    provincia: 'ANDORRA',
    slug: 'andorra',
    lat: '42.5000000',
    lon: '1.6000000',
  },
  {
    area: 'A CORUÑA',
    provincia: 'A CORUÑA',
    slug: 'a-coruna',
    lat: '43.3715',
    lon: '-8.39597',
  },
  {
    area: 'LUGO',
    provincia: 'LUGO',
    slug: 'lugo',
    lat: '43.0121',
    lon: '-7.55585',
  },
  {
    area: 'OURENSE',
    provincia: 'OURENSE',
    slug: 'ourense',
    lat: '42.34',
    lon: '-7.86464',
  },
  {
    area: 'PONTEVEDRA',
    provincia: 'PONTEVEDRA',
    slug: 'pontevedra',
    lat: '42.431',
    lon: '-8.64435',
  },
  {
    area: 'NAVARRA',
    provincia: 'NAVARRA',
    slug: 'navarra',
    lat: '42.81687',
    lon: '-1.64323',
  },
  {
    area: 'ALAVA',
    provincia: 'ALAVA',
    slug: 'alava',
    lat: '42.5818615',
    lon: '-2.6386976',
  },
  {
    area: 'LA RIOJA',
    provincia: 'LA RIOJA',
    slug: 'la-rioja',
    lat: '42.4627195',
    lon: '-2.4449852',
  },  
]

function inArea(taller, area) {
  // Es el "area" global (todos los talleres)
  if (!area.provincia) {
    return true
  }

  if (area.provincia !== taller.provincia) {
    return false
  }

  // Es un area sin división por poblacion
  if (!area.poblacion) {
    return true
  }

  if ('include' in area.poblacion) {
    return taller.poblacion.trim().startsWith(area.poblacion.include)
  } else {
    return !taller.poblacion.trim().startsWith(area.poblacion.exclude)
  }
}

function whichArea(taller) {
  return Areas.filter((a) => !!a.area).find((a) => inArea(taller, a))
}

function displayName(area) {
  return `areas.${slugify(area.area)}`
}

function getCoordinates(area) {
  const { lat, lon } = Areas.find((a) => a.area === area)
  return { lat, lon }
}

module.exports = { Areas, inArea, whichArea, displayName, getCoordinates }
